

















































































































import { Component, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import FlashMessageMixin from '@/mixins/flashMessageMixin'
import FormTopicModal from '@/partials/modals/Events/Topics/form.vue'
import GenericDeleteModal from '@/partials/modals/generic/delete.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

type SubmitType = 'create' | 'edit' | 'delete'

@Component({
  components: {
    Settings16,
    TrashCan16,
    ArrowDown16,
    ArrowUp16,
    Edit16,
    FormTopicModal,
    GenericDeleteModal
  }
})
export default class EventActivityModules extends Mixins(FlashMessageMixin, PermissionsMixin) {
  createModalIsOpened = false
  editModalIsOpened = false
  deleteModalIsOpened = false
  messageModalIsOpened = false
  modules = []

  selectedModule = {}

  errorMessage = ''

  created () {
    this.fetch()
  }

  fetch () {
    axios.get(`event/${this.$route.params.id}/activity-module`)
      .then(response => {
        this.modules = camelCaseKeys(response.data.data).map((mod: any, index: number) => {
          mod.order = index
          return mod
        })
      })
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.modules[targetIndex]

    this.modules.splice(targetIndex, 1)
    this.modules.splice(index, 0, swappingChoice)

    this.submitModulesOrder()
  }

  submitModulesOrder () {
    const orderedModules = this.modules.map((mod: any, index: number) => {
      return { id: mod.id, order: index }
    })

    const submitForm = {
      modules: orderedModules
    }

    axios.put(`event/${this.$route.params.id}/activity-module/reorder`, submitForm)
      .then((response) => {
        this.modules = camelCaseKeys(response.data.data).map((mod: any, index: number) => {
          mod.order = index
          return mod
        })
      })
  }

  postSubmit (type: SubmitType) {
    let verb = ''
    const closeModal = {
      create: () => {
        this.createModalIsOpened = false
        verb = 'cadastrado'
      },
      edit: () => {
        this.editModalIsOpened = false
        verb = 'editado'
      },
      delete: () => {
        this.deleteModalIsOpened = false
        verb = 'removido'
      }
    }

    closeModal[type]()

    const flashMessage = {
      message: `O módulo foi ${verb} com sucesso.`,
      isSuccess: true
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
